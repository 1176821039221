<template>
  <main>
    <!-- The stage from the config page. -->
    <HomeStage :home-config="homeConfig" />

    <!-- The content from the node. -->
    <BlokkliProvider v-bind="nodeProviderProps">
      <BlokkliField name="field_paragraphs" :list="node?.paragraphs" />
    </BlokkliProvider>
  </main>
</template>

<script lang="ts" setup>
import { definePageMeta } from '#imports'
import type {
  HomeConfigFragment,
  NodePageFragment,
  RouteHomeQuery,
} from '#graphql-operations'

definePageMeta({
  name: 'home',
})

const nuxtRoute = useRoute()
const nuxtApp = useNuxtApp()

// Get the data.
const { data: query } = await useAsyncData<RouteHomeQuery>(
  nuxtRoute.path,
  async () => {
    const { data } = await useCachedGraphqlQuery('routeHome', {
      path: nuxtRoute.path,
    })
    return data
  },
  {
    getCachedData: (key) => {
      return nuxtApp.payload.data[key]
    },
  },
)

// Handles redirects and metatags.
const { entity: node } = await useDrupalRoute<NodePageFragment>(query)

const homeConfig = computed<HomeConfigFragment | undefined>(
  () => query.value?.homeConfig,
)
const nodeProviderProps = buildBlokkliProps(node)

// Cache the front page for 20 minutes.
useRouteCache((ctx) => ctx.setMaxAge(60 * 20).setCacheable())

const { trackPageView } = useAnalyticsTracking()
trackPageView()
</script>

<script lang="ts">
export default {
  name: 'Home',
}
</script>

<style lang="scss" scoped>
:deep(.paragraph),
.paragraph {
  &:first-of-type {
    @apply mt-[135px] md:mt-80;
  }

  &:last-of-type {
    @apply mb-[82px] md:mb-[192px];
  }

  @include paragraph-spacing;
}
</style>
